import '../static/css/Youtube.css';
import { useGetYoutubeVideos } from '../hooks/useGetYoutubeVideos';
import { YoutubeFilled } from '@ant-design/icons';
import Loader from './Loader';

function encodeHtml(str: string) {
    return str.replace('&#39;', "'").replace('&quot;', '"').replace('&gt;', '>').replace('&lt;', '<').replace('&amp;', '&');
}

export const YouTube = (): JSX.Element => {
    const { videos, loading } = useGetYoutubeVideos();

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="youtube__wrap">
            <h2>Latest videos</h2>
            <div className="youtube fly-left">
                {videos?.map((video) => (
                    <div key={video.etag} className="video">
                        <a href={`https://www.youtube.com/watch?v=${video?.id?.videoId}`} target="_blank" rel="noreferrer">
                            <img src={video.snippet?.thumbnails?.medium?.url} alt={video.snippet?.title} />
                            <div className="icon-overlay">
                                <YoutubeFilled />
                            </div>
                        </a>
                        <h3>{encodeHtml(video.snippet?.title)}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
};