import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import '../static/css/Calendar.css';
import useGetCalendarEvents, { Event } from '../hooks/useGetCalendarEvents';
import twitch from '../static/twitch.png';
import datdrop from '../static/giveaways/datdrop.png';
import kick from '../static/kick.png';
import { useEffect, useState } from 'react';
import Loader from './Loader';
import empiredrop from '../static/giveaways/empiredrop.png';

function eventClick(info: any) {
    if (info.event.extendedProps.link) {
        window.open(info.event.extendedProps.link, '_blank');
    }
}

export default function Calendar() {
    const { events, loading, error } = useGetCalendarEvents();
    const [calendarOptions, setCalendarOptions] = useState({
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
            left: '',
            center: '',
            right: ''
        },
        eventColor: 'transparent',
        eventTextColor: '#fff',
        eventBorderColor: 'transparent',
        displayEventTime: true,
        eventClick: eventClick,
        eventContent: function (arg: any) {
            return (
                <div className="event__wrap">
                    <img src={arg.event.extendedProps.image_url} alt={`${arg.event.title} icon`} className={`event__icon event__icon__${arg.event.title}`} />
                    <p className="event__time">{arg.event.extendedProps.time}</p>
                </div>
            );
        },
        aspectRatio: 1.5,
        height: 'auto',
    });

    useEffect(() => {
        function updateCalendarSettings() {
            const isMobile = window.innerWidth <= 768;
            setCalendarOptions(prev => ({
                ...prev,
                aspectRatio: isMobile ? 0.75 : 1.5,
            }));
        }

        updateCalendarSettings();
        window.addEventListener('resize', updateCalendarSettings);

        return () => {
            window.removeEventListener('resize', updateCalendarSettings);
        }
    }, []);

    if (loading) return <Loader />;
    if (error) return <p>Error: {error}</p>;

    const transformedEvents = events.map((rawEvent: any) => {
        const event: Event = rawEvent.attributes;
        
        const transformedEvent: any = {
            title: event.type,
            date: event.date,
            display: 'block',
            link: event.url,
            time: event.time,
        };
        if (event.recurring) {
            transformedEvent.daysOfWeek = [];
            if (event.monday) transformedEvent.daysOfWeek.push(1);
            if (event.tuesday) transformedEvent.daysOfWeek.push(2);
            if (event.wednesday) transformedEvent.daysOfWeek.push(3);
            if (event.thursday) transformedEvent.daysOfWeek.push(4);
            if (event.friday) transformedEvent.daysOfWeek.push(5);
            if (event.saturday) transformedEvent.daysOfWeek.push(6);
            if (event.sunday) transformedEvent.daysOfWeek.push(0);
        }
        if (event.type === 'twitch') {
            transformedEvent.image_url = twitch;
        } else if (event.type === 'datdrop') {
            transformedEvent.image_url = datdrop;
        } else if (event.type === 'kick') {
            transformedEvent.image_url = kick;
        } else if (event.type === 'empiredrop') {
            transformedEvent.image_url = empiredrop;
        }
        return transformedEvent;
    });

    return (
        <div className="calendar-wrap" id="calendar">
            <h2 style={{marginBottom: 0}}>Calendar</h2>
            <FullCalendar {...calendarOptions} events={transformedEvents} />
        </div>
    );
}
