import '../static/css/Referrals.css';
import useGetReferrals from "../hooks/useGetReferrals";
import Loader from './Loader';

export const Referrals = (): JSX.Element => {
    const { referrals, loading, error } = useGetReferrals();

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="referrals__wrap">
            <h2>Referrals</h2>
            <div className="referrals">
                {referrals?.map((referral) => (
                    <a key={referral.site}
                        href={referral.url} 
                        className="referral__box"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={referral.logo} alt={referral.site}/>
                        <p>{referral.code}</p>
                        <p className="bonus text-xs">{referral.bonus}</p>
                    </a>
                ))}
            </div>
        </div>
    );
}
