import React, { createContext, useContext, useReducer, useEffect, ReactNode } from 'react';
import axios from 'axios';

// Define types for the state and actions
interface BalanceState {
	balance: number;
	isLoading: boolean;
}

interface BalanceAction {
	type: 'SET_BALANCE' | 'LOADING';
	payload?: number;
}

// Initial state for the reducer
const initialState: BalanceState = {
	balance: 0,
	isLoading: false,
};

// Reducer function
const balanceReducer = (state: BalanceState, action: BalanceAction): BalanceState => {
	switch (action.type) {
		case 'SET_BALANCE':
			return {
				...state,
				balance: action.payload || 0,
				isLoading: false,
			};
		case 'LOADING':
			return {
				...state,
				isLoading: true,
			};
		default:
			return state;
	}
};

// Define the context type
interface BalanceContextProps {
	state: BalanceState;
	setBalance: (newBalance: number) => void;
	fetchBalance: () => Promise<void>;
}

// Create context
const BalanceContext = createContext<BalanceContextProps | undefined>(undefined);

// Provider component
export const BalanceProvider = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(balanceReducer, initialState);

	// Function to set balance
	const setBalance = (newBalance: number) => {
		dispatch({ type: 'SET_BALANCE', payload: newBalance });
	};

	// Function to fetch balance from API
	const fetchBalance = async () => {
		dispatch({ type: 'LOADING' });
		try {
			const response = await axios.get<{ balance: number }>(process.env.REACT_APP_BASE_URL + '/api/v1/cases/users/balance', {
				withCredentials: true,
			});
			dispatch({ type: 'SET_BALANCE', payload: response.data.balance });
		} catch (error) {
			console.error('Failed to fetch balance', error);
			// Handle error case if needed
		}
	};

	useEffect(() => {
		fetchBalance(); // Fetch balance on mount
	}, []);

	return (
		<BalanceContext.Provider value={{ state, setBalance, fetchBalance }}>
			{children}
		</BalanceContext.Provider>
	);
};

// Custom hook to use the balance context
export const useBalance = (): BalanceContextProps => {
	const context = useContext(BalanceContext);
	if (!context) {
		throw new Error('useBalance must be used within a BalanceProvider');
	}
	return context;
};
