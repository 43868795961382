import Calendar from "../components/Calendar"
import { Referrals } from "../components/Referrals"
import Socials from "../components/Socials"
import { YouTube } from "../components/Youtube"
import { Helmet } from 'react-helmet'

export const Home = (): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Exodia Rewards</title>
                <meta name="description" content="Use referral codes, stay up to date with upcoming events, open cases, and earn rewards from our partners." />
                <link rel="canonical" href="https://exodiarewards.com" />
            </Helmet>
            <section>
                <div className="custom__container">
                    <Socials />
                    <Referrals />
                    <YouTube />
                    <Calendar />
                </div>
            </section>
        </>
    )
}