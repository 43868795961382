import axios from 'axios';
import { useState, useEffect } from 'react';

export interface HomePage {
    kick_live: boolean;
    twitch_live: boolean;
}

const useGetHomePage = () => {
    const [homePage, setHomePage] = useState<HomePage>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchReferrals = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/home-page`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                });
                const homePage: HomePage = {
                    kick_live: response.data.data.attributes.kick_live,
                    twitch_live: response.data.data.attributes.twitch_live
                };
                setHomePage(homePage);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchReferrals();
    }, []);

    return { homePage, loading, error };
};

export default useGetHomePage;
