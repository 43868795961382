/* eslint-disable */
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Deposit } from '../interfaces/csgobig';

export interface DailyDepositsResponse {
    results: DailyDepositsEntry[];
    startDate: string;
    endDate: string;
    cachedUntil: string;
}

export interface DailyDepositsEntry {
    index: number;
    deposits: Deposit[];
    date: string;
}

const useGetDailyDeposits = (viewPrevious: boolean = false) => {
    const [dailyDeposits, setDailyDeposits] = useState<DailyDepositsEntry[]>([]);
    const [endDate, setEndDate] = useState<string>('');
    const [cachedUntil, setCachedUntil] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchDailyDeposits = async () => {
            try {
                const response = await axios.get<DailyDepositsResponse>(`${process.env.REACT_APP_STRAPI_URL}/api/csgobig/daily${viewPrevious ? '/prev' : ''}`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                });
                response.data.results.forEach((entry) => {
                    entry.deposits.forEach((deposit) => {
                        deposit.totalDeposits = deposit.totalDeposits / 1.69;
                    });
                });
                setEndDate(response.data.endDate);
                setCachedUntil(response.data.cachedUntil);
                const processedDeposits = response.data.results.map(day => {
                    day.deposits.forEach(deposit => {
                        if (deposit.totalRewards !== 0 && deposit.totalDeposits >= 75) {
                            let dayIndex = day.index;
                            
                            let nextDayIndex = dayIndex + 1;
                            let excess = deposit.totalDeposits - 75;

                            deposit.totalDeposits = 75;
        
                            let nextDay = response.data.results.find(d => d.index === nextDayIndex);
                            
                            if (nextDay) {
                                let nextDeposit = nextDay.deposits.find(d => d.name === deposit.name);
                                if (nextDeposit) {
                                    nextDeposit.totalDeposits += excess;
                                    nextDeposit.totalRewards += deposit.totalRewards;
                                } else {
                                    nextDay.deposits.push({
                                        ...deposit,
                                        totalDeposits: excess
                                    });
                                }
                            }
                        }
                    });
                    return day;
                });
                setDailyDeposits(processedDeposits);
         
            } catch (err: any) {
                setError(err.message || 'An unknown error occurred');
            } finally {
                setLoading(false);
            }
        };

        fetchDailyDeposits();
    }, [viewPrevious]);

    return { dailyDeposits, loading, error, endDate, cachedUntil };
};

export default useGetDailyDeposits;
