import React from 'react';
import { useBalance } from '../context/BalanceContext';
import { FaCoins } from 'react-icons/fa';

export const formatBalanceWithIcon = (amount: string) => (
    <div className="flex items-center gap-1">
        <FaCoins className="text-[--color-gold]" />
        <span>{parseFloat(amount).toFixed(2)}</span>
    </div>
);

export const Balance = () => {
	const { state: { balance } } = useBalance();

	return (
		<div className="bg-black rounded-lg bg-opacity-50 text-white px-4 p-1 flex gap-1 items-center">
			<FaCoins className="text-[--color-gold]" />
            <span className="mt-1">{(balance / 100).toFixed(2)}</span>
		</div>
	);
};
