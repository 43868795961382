import axios from 'axios';

import { useState, useEffect } from 'react';

interface DatdropBattle {
    url: string;
    value: number;
    createdAt: string;
    min_deposit?: number;
    min_deposit_time_value?: number;
    min_deposit_time_unit?: string;
}

const useGetDatdropBattles = () => {
    const [battles, setBattles] = useState<DatdropBattle[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchBattles = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/datdrop-battles?populate=*&pagination[pageSize]=100&sort=createdAt:desc`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                });

                const battles: DatdropBattle[] = response.data.data.map((battle: any) => ({
                    url: battle.attributes.url,
                    value: battle.attributes.value,
                    createdAt: battle.attributes.createdAt,
                    min_deposit: battle.attributes.min_deposit,
                    min_deposit_time_value: battle.attributes.min_deposit_time_value,
                    min_deposit_time_unit: battle.attributes.min_deposit_time_unit
                }));
                // filter out battles that are older than 1 week
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
                const filteredBattles = battles.filter(battle => new Date(battle.createdAt) > oneWeekAgo);
                const sortedBattles = filteredBattles.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                setBattles(sortedBattles);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBattles();
    }, []);

    return { battles, loading, error };
};

export default useGetDatdropBattles;
