import axios from 'axios';

import { useState, useEffect } from 'react';

export interface RewardsPageInfo {
    title: string;
    info: string;
    subtitle: string;
    warning: string;
}

const useGetRewardsPageInfo = () => {
    const [rewardsPageInfo, setRewardsPageInfo] = useState<RewardsPageInfo>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchReferrals = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/csgo-big-reward`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                });
                const rewardsPageInfo: RewardsPageInfo = {
                    title: response.data.data.attributes.title,
                    info: response.data.data.attributes.info,
                    subtitle: response.data.data.attributes.subtitle,
                    warning: response.data.data.attributes.warning
                };
                setRewardsPageInfo(rewardsPageInfo);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchReferrals();
    }, []);

    return { rewardsPageInfo, loading, error };
};

export default useGetRewardsPageInfo;
