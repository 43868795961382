import { useState } from "react";
import Loader from "../components/Loader";
import useGetDailyDeposits from "../hooks/useGetDailyDeposits";
import { Deposit } from "../interfaces/csgobig";
import '../static/css/Cashout.css';
import useGetCashoutPageInfo from "../hooks/useGetCashoutPageInfo";
import Markdown from "react-markdown";
import CountdownTimer from "../components/Countdown";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const Cashout = (): JSX.Element => {
    const [viewPrevious, setViewPrevious] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { dailyDeposits, loading, error, endDate, cachedUntil } = useGetDailyDeposits(viewPrevious);
    const { loading: pageInfoLoading, cashoutPageInfo } = useGetCashoutPageInfo();

    if (loading) return <Loader />;
    if (pageInfoLoading) return <Loader />;

    if (error) {
        toast.error(error);
    }

    const toggleView = () => {
        setViewPrevious(!viewPrevious);
    };

    const tableRows = dailyDeposits.reduce((acc: any, day: any) => {
        day.deposits.forEach((deposit: Deposit) => {
            if (deposit.totalRewards === 0) return;
            if (!acc[deposit.name]) {
                acc[deposit.name] = { days: Array(7).fill(null), totalWeekDeposits: 0 };
            }
            let dayIndex = day.index;
            if (viewPrevious) {
                dayIndex += 7;
            }
            acc[deposit.name].days[dayIndex] = deposit.totalDeposits >= 75 ? '\u2713' : '';
            acc[deposit.name].totalWeekDeposits += deposit.totalDeposits;
        });
        return acc;
    }, {});

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredAndSortedTableRows = Object.entries(tableRows)
        .filter(([name]) => name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => a[0].localeCompare(b[0]));

    return (
        <>
            <Helmet>
                <title>Cashout | Exodia Rewards</title>
                <meta name="description" content="Take part in a daily cashout by depositing daily on CSGOBig using code Exo." />
                <link rel="canonical" href="https://exodiarewards.com/cashout" />
            </Helmet>
            <section>
                <div className="custom__container fade-in">
                    <h1 className="title text-center">{cashoutPageInfo?.title}</h1>
                    <p className="note">
                        <Markdown>
                            {cashoutPageInfo?.info}
                        </Markdown>
                    </p>
                    <p className="text-center">
                        The data is updated every half an hour, with the next update occuring in <CountdownTimer endDate={cachedUntil} />
                    </p>
                    <div className="flex justify-between flex-col-reverse lg:flex-row items-center">
                        <div className="search__wrapper">
                            <input
                                type="text"
                                className="search-input min-w-96"
                                placeholder="Search by name..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="last__week__bonuses mt-4">
                            <p>Last week's reward:</p>
                            <span className="reward">{cashoutPageInfo?.payout}c</span>
                        </div>
                    </div>
                    <br />
                    <br />
                    {endDate && new Date(endDate) <= new Date() && <p className="display__text">
                        Displaying {dailyDeposits[0].date} - {new Date(new Date(dailyDeposits[dailyDeposits.length - 1].date).getTime() + 24 * 60 * 60 * 1000).toISOString().slice(0, 10)}
                    </p>}
                    {endDate && new Date(endDate) > new Date() ? <p className="display__text">Resets in <CountdownTimer endDate={endDate} /></p> : ''}
                    <button onClick={toggleView} className="toggle-button">
                        {viewPrevious ? 'View Current Week' : 'View Previous Week'}
                    </button>
                    <table className="cashout__table">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Week</th>
                                {daysOfWeek.map(day => <th key={day}>{day}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredAndSortedTableRows.map(([name, details]: any) => (
                                <tr key={name}>
                                    <td>{name}</td>
                                    <td>{details.totalWeekDeposits >= 450 ? '\u2713' : ''}</td>
                                    {details.days.map((mark: any, index: any) => (
                                        <td key={index}>
                                            <p>{details.totalWeekDeposits < 450 && <span className="checkmark">{mark || ''}</span>}</p>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}
