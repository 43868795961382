import axios from 'axios';
import { useState, useEffect, useCallback, useRef } from 'react';
import { EmpireDropDeposit, EmpireDropResponse } from './useGetEmpireDropDeposits';

export const useGetEmpiredropDepositsList = (start: Date | null, end: Date | null, prev: boolean | null) => {
    const [deposits, setDeposits] = useState<EmpireDropDeposit[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [cachedUntil, setCachedUntil] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);

    const fetchEmpireDropDeposits = useCallback(async () => {
        let endpoint = '/api/empiredrop/deposits';

        // Build query parameters based on available values
        const params = new URLSearchParams();
        if (start) params.append('startDate', start.toISOString());
        if (end) params.append('endDate', end.toISOString());
        if (prev !== null) params.append('prev', prev.toString());

        // Append query parameters if any are present
        if (params.toString()) endpoint += `?${params.toString()}`;

        try {
            const response = await axios.get<EmpireDropResponse>(`${process.env.REACT_APP_STRAPI_URL}${endpoint}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                    'Content-Type': 'application/json'
                }
            });
            setDeposits(response.data.ranking);
            setCachedUntil(response.data.cachedUntil);
            setStartDate(response.data.startDate);
            setEndDate(response.data.endDate);
        } catch (err: any) {
            setError(err.message || 'An error occurred while fetching the data');
        } finally {
            setLoading(false);
        }
    }, [start, end, prev]);

    const fetchEmpireDropDepositsRef = useRef(fetchEmpireDropDeposits);

    useEffect(() => {
        fetchEmpireDropDepositsRef.current = fetchEmpireDropDeposits;
    }, [fetchEmpireDropDeposits]);

    useEffect(() => {
        fetchEmpireDropDepositsRef.current();
    }, []);

    return { deposits, loading, error, startDate, endDate, cachedUntil, fetchEmpireDropDeposits };
};
