import React, { createContext, useContext, useReducer, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';

// Define types for the state and actions
interface AuthState {
	user: User | null;
	isLoading: boolean;
	isAuthenticated: boolean;
	isAdmin: boolean;
}

export interface User {
	steamid64: string;
    personaname: string;
    avatar: string;
}

interface AuthAction {
	type: 'AUTH_SUCCESS' | 'AUTH_FAILURE' | 'LOADING' | 'ADMIN_SUCCESS' | 'ADMIN_FAILURE';
	payload?: User | null;
}

// Initial state for the reducer
const initialState: AuthState = {
	user: null,
	isLoading: true,
	isAuthenticated: false,
	isAdmin: false,
};

// Reducer function
const authReducer = (state: AuthState, action: AuthAction): AuthState => {
	switch (action.type) {
		case 'AUTH_SUCCESS':
			return {
				...state,
				user: action.payload || null,
				isAuthenticated: true,
				isLoading: false,
			};
		case 'AUTH_FAILURE':
			return {
				...state,
				user: null,
				isAuthenticated: false,
				isLoading: false,
				isAdmin: false, // Reset admin status on failure
			};
		case 'LOADING':
			return {
				...state,
				isLoading: true,
			};
		case 'ADMIN_SUCCESS':
			return {
				...state,
				isAdmin: true,
			};
		case 'ADMIN_FAILURE':
			return {
				...state,
				isAdmin: false,
			};
		default:
			return state;
	}
};

// Define the context type
interface AuthContextProps {
	state: AuthState;
}

// Create context
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Provider component
export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(authReducer, initialState);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchProfile = async () => {
			dispatch({ type: 'LOADING' });
			try {
				const response = await axios.get<User>(process.env.REACT_APP_BASE_URL + '/api/v1/profile', {
					withCredentials: true,
				});
				dispatch({ type: 'AUTH_SUCCESS', payload: response.data });

				// Check if user is admin
				try {
					await axios.get(process.env.REACT_APP_BASE_URL + '/api/v1/cases/admin', {
						withCredentials: true,
					});
					dispatch({ type: 'ADMIN_SUCCESS' });
				} catch (adminError: any) {
					if (adminError.response?.status === 401 || adminError.response?.status === 403) {
						dispatch({ type: 'ADMIN_FAILURE' });
					} else {
						console.log('Error checking admin status:', adminError);
					}
				}
			} catch (error: any) {
				if (error.response?.status === 401) {
					try {
						// Attempt token refresh
						await axios.post(process.env.REACT_APP_BASE_URL + '/api/v1/auth/steam/refresh', {}, { withCredentials: true });
						// Retry fetching profile
						const retryResponse = await axios.get<User>(process.env.REACT_APP_BASE_URL + '/api/v1/profile', {
							withCredentials: true,
						});
						dispatch({ type: 'AUTH_SUCCESS', payload: retryResponse.data });

						// Retry checking if user is admin
						try {
							await axios.get(process.env.REACT_APP_BASE_URL + '/api/v1/cases/admin', {
								withCredentials: true,
							});
							dispatch({ type: 'ADMIN_SUCCESS' });
						} catch (adminRetryError: any) {
							if (adminRetryError.response?.status === 401 || adminRetryError.response?.status === 403) {
								dispatch({ type: 'ADMIN_FAILURE' });
							}
						}
					} catch (refreshError) {
						console.log(refreshError);
						// If refresh fails, user is not authenticated
						dispatch({ type: 'AUTH_FAILURE' });
					}
				} else {
					dispatch({ type: 'AUTH_FAILURE' });
				}
			}
		};

		fetchProfile();
	}, [navigate]);

	return (
		<AuthContext.Provider value={{ state }}>
			{state.isLoading ? <Loader /> : children}
		</AuthContext.Provider>
	);
};

// Custom hook to use the auth context
export const useAuth = (): AuthState => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context.state;
};
