import React, { useState, useEffect, useRef } from 'react';
import '../static/css/Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import logo from '../static/logo.png';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import csgobig from '../static/csgobig.png';
import datdrop from '../static/datdrop.png';
import empiredrop from '../static/empiredrop.png';
import steamLoginImage from '../static/img/steam.png';
import { useAuth } from '../context/AuthContext';
import Loader from './Loader';
import { Balance } from './Balance';
import { EMPIREDROP_MIN_DEPOSIT } from '../pages/EmpiredropDeposits';

export const Navbar = (): JSX.Element => {
    const { isAuthenticated, isLoading, user, isAdmin } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const [scrolled, setScrolled] = useState(false);
    const [datdropOpen, setDatdropOpen] = useState(false);
    const [csgobigOpen, setCsgobigOpen] = useState(false);
    const [empiredropOpen, setEmpiredropOpen] = useState(false);  // New state for EmpireDrop dropdown
    const datdropRef = useRef<HTMLLIElement>(null);
    const csgobigRef = useRef<HTMLLIElement>(null);
    const empiredropRef = useRef<HTMLLIElement>(null);  // New ref for EmpireDrop dropdown

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setIsOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [isOpen]);

    const handleScrollToCalendar = () => {
        setIsOpen(false);
        const calendarElement = document.getElementById('calendar');
        if (calendarElement) {
            calendarElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    };

    useEffect(() => {
        if (location.hash === '#calendar') {
            setTimeout(() => {
                handleScrollToCalendar();
            }, 250);
        }
    }, [location.hash]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (datdropRef.current && !datdropRef.current.contains(event.target)) {
                setDatdropOpen(false);
            }
            if (csgobigRef.current && !csgobigRef.current.contains(event.target)) {
                setCsgobigOpen(false);
            }

            if (empiredropRef.current && !empiredropRef.current.contains(event.target)) {
                setEmpiredropOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <header className={scrolled ? 'scrolled' : ''}>
            <div className="custom__container">
                <nav>
                    <h1>
                        <Link to="/"
                            className="logo__link"
                            onClick={() => { setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }) }}>
                            <img src={logo} alt="Exodia Rewards" height="50" className="h-12" />
                            <span className="font-bold hidden lg:block">Exodia Rewards</span>
                        </Link>
                    </h1>
                    {/* show balance only on mobile */}
                    <div className="flex items-center gap-2 md:hidden">
                        {isAuthenticated && !isLoading && user && (
                            <div className="flex gap-2 items-center justify-center">
                                <Balance />
                                <Link to="/profile" onClick={() => setIsOpen(false)} className="mt-2">
                                    <img src={user.avatar} alt={user.personaname} height="30" width="30" className="avatar h-6" />
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={() => { setIsOpen(!isOpen) }}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <ul className={isOpen ? 'nav-links open' : 'nav-links'}>
                        {isAdmin && <li>
                            <Link to="/admin" 
                                  onClick={() => { setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }) }}
                                  className="text-red-600 hover:underline hover:text-red-700">
                                Admin
                            </Link>
                        </li>}
                        {/* {isAuthenticated && <li>
                            <Link to="/parlays" onClick={() => { setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }) }} className="relative flex gap-2 hover:no-underline">
                                Parlays
                                <span className=" bg-[--color-gold] text-black text-xs px-2 py-0.5 rounded-full">NEW</span>
                            </Link>
                        </li>} */}
                        {isAuthenticated && <li>
                            <Link to="/cases" onClick={() => { setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }) }} className="hover:no-underline">
                                Cases
                            </Link>
                        </li>}
                        <li className="dropdown" ref={empiredropRef}>
                            <div className="dropdown__toggle" onClick={() => setEmpiredropOpen(!empiredropOpen)}>
                                <img src={empiredrop} alt="EmpireDrop" height="30" className="h-7" />
                                {empiredropOpen ? <CaretUpFilled /> : <CaretDownFilled />}
                            </div>
                            <ul className={`dropdown-menu w-96 ${empiredropOpen ? 'show' : ''}`}>
                                <li>
                                    <Link to="/empiredrop" onClick={() => { setIsOpen(false); setEmpiredropOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                                        Tickets
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/empiredrop/competition" onClick={() => { setIsOpen(false); setEmpiredropOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                                        Competition
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/empiredrop/deposits" onClick={() => {setIsOpen(false); setEmpiredropOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                                        €{EMPIREDROP_MIN_DEPOSIT} Wager comp
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="dropdown" ref={datdropRef}>
                            <div className="dropdown__toggle" onClick={() => setDatdropOpen(!datdropOpen)}>
                                <img src={datdrop} alt="Datdrop Competitions" height="25" className='h-6' />
                                {datdropOpen ? <CaretUpFilled /> : <CaretDownFilled />}
                            </div>
                            <ul className={`dropdown-menu ${datdropOpen ? 'show' : ''}`}>
                                <li>
                                    <Link to="/datdrop" onClick={() => { setIsOpen(false); setDatdropOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                                        Free Battles
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/datdrop100" onClick={() => { setIsOpen(false); setDatdropOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                                        $100 competition
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/datdrop500" onClick={() => { setIsOpen(false); setDatdropOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                                        $500 competition
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="dropdown" ref={csgobigRef}>
                            <div className="dropdown__toggle" onClick={() => setCsgobigOpen(!csgobigOpen)}>
                                <img src={csgobig} alt="CSGOBig Rewards" height="25" className='h-6' />
                                {csgobigOpen ? <CaretUpFilled /> : <CaretDownFilled />}
                            </div>
                            <ul className={`dropdown-menu ${csgobigOpen ? 'show' : ''}`}>
                                <li>
                                    <Link to="/cs2big/battles" onClick={() => { setIsOpen(false); setCsgobigOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                                        Free battles
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/tiers" onClick={() => { setIsOpen(false); setCsgobigOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                                        Rewards
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cashout" onClick={() => { setIsOpen(false); setCsgobigOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                                        Daily Cashout
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            {isLoading && <Loader />}
                            {isAuthenticated && !isLoading && user && (
                                <div className="flex gap-2 items-center justify-center">
                                    <Balance />
                                    <Link to="/profile" onClick={() => setIsOpen(false)} className="mt-0">
                                        <img src={user.avatar} alt={user.personaname} height="30" width="30" className="avatar h-6" />
                                    </Link>
                                </div>
                            )}
                            {!isAuthenticated && !isLoading && (
                                <a href={`${process.env.REACT_APP_BASE_URL}/api/v1/auth/steam`} className="hover:opacity-75 transition-all">
                                    <img src={steamLoginImage} alt="Steam Login" height="25" className="h-6" />
                                </a>
                            )}
                        </li>
                        {/* <li>
                            <Link to="/#calendar" onClick={() => handleScrollToCalendar()}>
                                Calendar
                            </Link>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </header>
    );
}