// src/components/AdminLayout.js
import React from "react";
import { NavLink, Outlet } from "react-router-dom";

export default function AdminLayout() {
    return (
        <div className="flex min-h-screen bg-gray-900 h-full">
            {/* Sidebar */}
            <aside className="w-64 bg-gray-800 text-white flex flex-col">
                <div className="text-xl font-bold p-4 bg-gray-800">Admin Panel</div>
                <div className="flex-1 px-4 py-2 gap-2">
                    <nav className="flex-col items-start gap-5 text-white">
                        <NavLink
                            to="/admin/balance"
                            className={({ isActive }) =>
                                `hover:opacity-75 ${isActive ? "text-[--color-gold]" : ""}`
                            }
                        >
                            Update Balances
                        </NavLink>
                        <NavLink
                            to="/admin/users"
                            className={({ isActive }) =>
                                `hover:opacity-75 ${isActive ? "text-[--color-gold]" : ""}`
                            }
                        >
                            User Balances (list)
                        </NavLink>
                        <NavLink
                            to="/admin/review"
                            className={({ isActive }) =>
                                `hover:opacity-75 ${isActive ? "text-[var(--color-gold)]" : ""}`
                            }
                        >
                            Review Case Opens
                        </NavLink>
                        <hr className="w-full border-gray-700" />
                        <NavLink
                            to="/admin/parlays/list"
                            className={({ isActive }) =>
                                `hover:opacity-75 ${isActive ? "text-[var(--color-gold)]" : ""}`
                            }
                        >
                            Parlays
                        </NavLink>
                        <NavLink
                            to="/admin/parlays/create"
                            className={({ isActive }) =>
                                `hover:opacity-75 ${isActive ? "text-[var(--color-gold)]" : ""}`
                            }
                        >
                            Create Parlay
                        </NavLink>
                        <hr className="w-full border-gray-700" />
                        {/* return to site */}
                        <NavLink
                            to="/"
                            className={({ isActive }) =>
                                `hover:opacity-75 ${isActive ? "text-[var(--color-gold)]" : ""}`
                            }
                        >
                            Return to Site
                        </NavLink>
                    </nav>
                </div>
            </aside>
            {/* Main Content */}
            <main className="flex-1 p-6">
                <Outlet />
            </main>
        </div>
    );
}