import axios from 'axios';
import { useState, useEffect } from 'react';


export const useGetConversionRates = () => {
    const [conversionRate, setConversionRate] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchConversionRates = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/empiredrop/conversion`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                });
                setConversionRate(response.data.data['USD']);
            } catch (err: any) {
                setError(err.message || 'An error occurred while fetching the data');
            } finally {
                setLoading(false);
            }
        };

        fetchConversionRates();
    }, []);

    return { conversionRate, loading, error };
};
