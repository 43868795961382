import { useEffect, useState } from "react";

interface TimeLeft {
    d?: number;
    h?: number;
    m?: number;
    s?: number;
}

const CountdownTimer = ({ endDate, whenFinished = () => {} }: { endDate: string, whenFinished?: () => void }) => {
    const calculateTimeLeft = (): TimeLeft => {
        const difference = +new Date(endDate) - +new Date();
        let timeLeft: TimeLeft = {};

        if (difference > 0) {
            timeLeft = {
                d: Math.floor(difference / (1000 * 60 * 60 * 24)),
                h: Math.floor((difference / (1000 * 60 * 60)) % 24),
                m: Math.floor((difference / 1000 / 60) % 60),
                s: Math.floor((difference / 1000) % 60),
            };
        } else {
            setTimeout(() => {
                whenFinished();
            }, 4000);
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const timerComponents: JSX.Element[] = [];

    Object.keys(timeLeft).forEach((interval) => {
        const value = timeLeft[interval as keyof TimeLeft]; // Use the key of TimeLeft to index
        if (!value) return; // Skip if value is undefined

        timerComponents.push(
            <span key={interval}>
                {value}{interval}{" "}
            </span>
        );
    });

    return (
        <span>
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </span>
    );
};

export default CountdownTimer;
