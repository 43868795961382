import axios from 'axios';

import { useState, useEffect } from 'react';

export interface Event {
    type: string;
    recurring: boolean;
    date?: Date;
    monday?: boolean;
    tuesday?: boolean;
    wednesday?: boolean;
    thursday?: boolean;
    friday?: boolean;
    saturday?: boolean;
    sunday?: boolean;
    url: string;
    time?: string;
}

const useGetCalendarEvents = () => {
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/events?populate=*`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                });
                setEvents(response.data.data);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

    return { events, loading, error };
};

export default useGetCalendarEvents;
