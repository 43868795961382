import { Routes, Route } from 'react-router-dom';
import './App.css';
import { Home } from './pages/Home';
import { Cashout } from './pages/Cashout';
import { Datdrop } from './pages/Datdrop';
import { Competition } from './components/Competition';
import { NotFound } from './pages/NotFound';
import { Empiredrop } from './pages/Empiredrop';
import { NewTiers } from './pages/NewTiers';
import { lazy, Suspense } from 'react';
import Loader from './components/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './context/AuthContext';
import AdminLayout from './layouts/AdminLayout';
import RegularLayout from './layouts/RegularLayout';

const EmpiredropDeposits = lazy(() => import('./pages/EmpiredropDeposits'));
const CS2BigBattles = lazy(() => import('./pages/CS2BigBattles'));
const Profile = lazy(() => import('./pages/Profile'));
const Cases = lazy(() => import('./pages/Cases'));
const CaseOpen = lazy(() => import('./pages/CaseOpen'));
const Admin = lazy(() => import('./pages/Admin/Admin'));
const AdminBalanceUpdate = lazy(() => import('./pages/Admin/AdminBalanceUpdate'));
const AdminReview = lazy(() => import('./pages/Admin/AdminReview'));
const AdminUsers = lazy(() => import('./pages/Admin/AdminUsers'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const AdminCreateParlay = lazy(() => import('./pages/Admin/AdminCreateParlay'));
const AdminParlays = lazy(() => import('./pages/Admin/AdminParlays'));
const Parlays = lazy(() => import('./pages/Parlays'));

function App() {
    const { isAuthenticated, isAdmin } = useAuth();
    return (
        <>
            <div className="App">
                <Routes>
                    <Route element={<RegularLayout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/tiers" element={<NewTiers />} />
                        <Route path="/cashout" element={<Cashout />} />
                        <Route path="/datdrop" element={<Datdrop />} />
                        <Route path="/cs2big/battles" element={<Suspense fallback={<Loader />}><CS2BigBattles /></Suspense>} />
                        <Route path="/empiredrop" element={<Empiredrop />} />
                        <Route
                            path="/empiredrop/competition"
                            element={<Competition
                                competitionName="empiredrop-comp"
                                title="EmpireDrop Competition"
                                description="Deposit on EmpireDrop, get tickets and win prizes in the form of crypto & site balance!"
                                canonical="https://exodiarewards.com/empiredrop/competition"
                            />} />
                        <Route path="/empiredrop/deposits" element={<Suspense fallback={<Loader />}><EmpiredropDeposits /></Suspense>} />
                        <Route path="/datdrop100"
                            element={<Competition
                                competitionName="datdrop-comp100"
                                title="Datdrop $100 Competition"
                                description="Deposit on datdrop using code Exo and get a chance to join a free battle!"
                                canonical="https://exodiarewards.com/datdrop100"
                            />} />
                        <Route path="/datdrop500"
                            element={<Competition
                                competitionName="datdrop-comp500"
                                title="Datdrop $500 Competition"
                                description="Deposit on datdrop using code Exo and get a chance to join a free battle!"
                                canonical="https://exodiarewards.com/datdrop500"
                            />} />
                        <Route path="/tos" element={<Suspense fallback={<Loader />}><TermsOfService /></Suspense>} />
                        <Route path="/*" element={<NotFound />} />
                        {isAuthenticated && <Route path="/cases" element={<Suspense fallback={<Loader />}><Cases /></Suspense>} />}
                        {isAuthenticated && <Route path="/cases/:id" element={<Suspense fallback={<Loader />}><CaseOpen /></Suspense>} />}
                        {isAuthenticated && <Route path="/profile" element={<Suspense fallback={<Loader />}><Profile /></Suspense>} />}
                        {isAuthenticated && <Route path="/parlays" element={<Suspense fallback={<Loader />}><Parlays /></Suspense>} />}
                    </Route>
                    {isAdmin && (
                        <Route path="/admin" element={<AdminLayout />}>
                            <Route path="" element={<Suspense fallback={<Loader />}><Admin /></Suspense>} />
                            <Route path="balance" element={<Suspense fallback={<Loader />}><AdminBalanceUpdate /></Suspense>} />
                            <Route path="users" element={<Suspense fallback={<Loader />}><AdminUsers /></Suspense>} />
                            <Route path="review" element={<Suspense fallback={<Loader />}><AdminReview /></Suspense>} />
                            <Route path="parlays/create" element={<Suspense fallback={<Loader />}><AdminCreateParlay /></Suspense>} />
                            <Route path="parlays/list" element={<Suspense fallback={<Loader />}><AdminParlays /></Suspense>} />
                        </Route>
                    )}

                </Routes>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="bg-black bg-opacity-90 text-white"
                    bodyClassName="text-sm"
                    progressClassName="bg-black"
                    toastStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.9)', color: 'white' }}
                />
            </div>
        </>
    );
}

export default App;
