// src/components/RegularLayout.js
import React from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

export default function RegularLayout() {
    return (
        <>
            <Navbar />
            <div className="App">
                <video autoPlay playsInline muted loop id="bg-video">
                    <source src="/STATIC_SHOT.mp4" type="video/mp4" />
                </video>
                <Outlet />
            </div>
            <Footer />
        </>
    );
}