import axios from 'axios';

import { useState, useEffect } from 'react';

interface Referral {
    site: string;
    bonus: string;
    code: string;
    url: string;
    logo: any;
    rank: number;
}

const useGetReferrals = () => {
    const [referrals, setReferrals] = useState<Referral[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchReferrals = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/referrals?populate=*`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                });
                const referrals: Referral[] = response.data.data.map((item: any) => ({
                    site: item.attributes.site,
                    bonus: item.attributes.bonus,
                    code: item.attributes.code,
                    url: item.attributes.url,
                    logo: item.attributes.logo ? process.env.REACT_APP_STRAPI_URL + item.attributes.logo.data.attributes.url : null,
                    rank: item.attributes.rank
                }));
                referrals.sort((a, b) => a.rank - b.rank);
                setReferrals(referrals);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchReferrals();
    }, []);

    return { referrals, loading, error };
};

export default useGetReferrals;
