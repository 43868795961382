import axios from 'axios';
import { useEffect, useState } from 'react';

export const useGetYoutubeVideos = () => {
    const [videos, setVideos] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_STRAPI_URL + '/api/youtube', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + process.env.REACT_APP_STRAPI_API_KEY,
                    }   
                });
                const data = response.data.items;
                setVideos(data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchVideos();
    }, []);

    return { videos, loading };
}