import { DiscordOutlined, TwitchOutlined, XOutlined, YoutubeOutlined } from '@ant-design/icons';
import '../static/css/Socials.css';
import { Kick } from '../static/kick';
import Loader from './Loader';
import useGetHomePage from '../hooks/useGetHomePage';
import live from '../static/live.png';

export default function Socials() {
    const { homePage, loading, error } = useGetHomePage();

    if (loading) return <Loader />;
    if (error) return <p>{error}</p>;

    return (
        <>
            <div className="big__socials">
                <a className="socials__wrap fly-bottom" href="https://twitter.com/ExodiaSecret" target="_blank" rel="noopener noreferrer">
                    <span><XOutlined style={{ fontSize: '2rem', color: '#FFCC00' }} /></span>
                </a>
                <a className="socials__wrap fly-bottom" href="https://www.youtube.com/@secretexodia" target="_blank" rel="noopener noreferrer">
                    <span><YoutubeOutlined style={{ fontSize: '2rem', color: '#FFCC00' }} /></span>
                </a>
                <a className="socials__wrap fly-bottom" href="https://discord.gg/Q7VEztD" target="_blank" rel="noopener noreferrer">
                    <span><DiscordOutlined style={{ fontSize: '2rem', color: '#FFCC00' }} /></span>
                </a>
                <a className="socials__wrap fly-bottom" href="https://www.twitch.tv/secretexodia" target="_blank" rel="noopener noreferrer">
                    {homePage?.twitch_live && <img src={live} alt="live" className="live__icon" height="15"/>}
                    <span><TwitchOutlined style={{ fontSize: '2rem', color: '#FFCC00' }} /></span>
                </a>
                <a className="socials__wrap fly-bottom" href="https://kick.com/secretexodia" target="_blank" rel="noreferrer">
                    {homePage?.kick_live && <img src={live} alt="live" className="live__icon" height="15"/>}
                    <span><Kick/></span>
                </a>
            </div>
        </>
    );
}